<template>
 <div class="my-box">

  <el-form :model="addform" ref="addform" :rules="myrules" label-width="100px">
   <el-form-item label="姓名" prop="employeeName">
    <el-input v-model.trim="addform.employeeName" :disabled="!(type=='edit')" placeholder="请输入内容" maxlength="15"></el-input>
   </el-form-item>
   <el-form-item label="手机号" prop="phoneNumber">
    <el-input v-model.trim="addform.phoneNumber" :disabled="!(type=='edit')" placeholder="请输入手机号" maxlength="15"></el-input>
   </el-form-item>

   <el-form-item label="职位" prop="position">
    <el-select v-model="addform.position" :disabled="!(type=='changeCard')" clearable placeholder="请选择">
     <el-option v-for="(item,index) in cardTypes" :key="index" :label="item.label" :value="item.value"></el-option>
    </el-select>
   </el-form-item>

   <el-form-item label="卡类型" prop="cardType">
    <el-select v-model="addform.cardType" :disabled="!(type=='changeCard')" clearable filterable placeholder="请选择">
     <el-option v-for="(item,index) in cardTypes" :key="index" :label="item.label" :value="item.value"></el-option>
    </el-select>
   </el-form-item>
   <el-form-item label="新卡号" prop="cardSn">
    <el-input v-model.trim="addform.cardSn" :disabled="!(type=='changeCard')" placeholder="请输入新卡号" maxlength="15"></el-input>
   </el-form-item>

  </el-form>
  <div slot="footer" class="dialog-footer">
   <el-button @click="close">取 消</el-button>
   <el-button type="primary" @click="confirmAdd('addform')" :loading="loading">确 定</el-button>
  </div>
  <!-- </el-dialog> -->
 </div>
</template>
<script>
import { workerChangeCard, workerInfoUpdate } from "@/api";

export default {
 props: {
  type: {
   type: String,
   default: "edit",
  },
 },
 data() {
  return {
   loading: false,
   addform: {
    employeeId: null,
    employeeName: null,
    position: null,
    cardType: null,
    cardSn: null,
    phoneNumber:null
   },

  };
 },
 computed: {
   myrules(){
     switch (this.type) {
      case "edit":
      return {
         phoneNumber: [
      { required: true, message: "请输入手机号码", trigger: "blur" },
     {
      pattern: /^1[345789]\d{9}$/,
      message: "请填写正确的手机号码",
      trigger: "blur",
     },
    ],
    employeeName: [
     { required: true, message: "请选择", trigger: "blur" },
     { max: 10, message: "最多可输入 10 个字符", trigger: "blur" },
    ],
      };
      case "changeCard":
      return {
         position: [{ required: true, message: "请选择", trigger: "change" }],
    cardType: [{ required: true, message: "请选择", trigger: "change" }],
    cardSn: [{ required: true, message: "请输入内容", trigger: "blur" }],
      };
      default:
        return {};
     }
   },
  count() {
   let val = 0;
   this.tablist.forEach((v) => {
    if (v.duration > 360) {
     val++;
    }
   });
   return val;
  },
  cardTypes() {
   return this.$store.state.cardTypes;
  },
 },
 created() {},
 methods: {
   close(){
     this.$emit('close')
   },
  openSet(data) {
   if (data) {
    this.addform = {
     brandId: data.brandId,
     brandName: data.brandName,
     hotelId: data.hotelId,
     hotelName: data.hotelName,
     employeeId: data.employeeId,
     employeeName: data.employeeName,
     position: data.position,
     cardType: data.cardType,
     cardSn: data.cardSn,
     phoneNumber: data.phoneNumber,
    };
   } else {
    this.addform = {
     brandId: null,
     brandName: null,
     hotelId: null,
     hotelName: null,
     employeeId: null,
     employeeName: null,
     position: null,
     cardType: null,
     cardSn: null,
     phoneNumber: null,
    };
   }
  },
  workerInfoUpdateReq() {
   let data = new Object(),
    keys = ["employeeId", "employeeName", "phoneNumber"];
   keys.forEach((key) => (data[key] = this.addform[key]));
   workerInfoUpdate(data)
    .then((res) => {
     console.log(res);
     this.loading = false;
     if (res.data.code == "000000") {
      this.$message.success(res.data.message);
      this.$emit("getList");
     } else {
      this.$message.error(res.data.message);
     }
    })
    .catch((err) => {
     console.log(err);
     this.loading = false;
    });
  },
  confirmAdd(formName) {
   this.$refs[formName].validate((valid) => {
    if (valid) {
     this.loading = true;
     console.log(this.addform);
     switch (this.type) {
      case "edit":
       this.workerInfoUpdateReq();
       break;
      case "changeCard":
       this.workerChangeCardReq();
       break;
      default:
       break;
     }
    } else {
     console.log("error submit!!");
     return false;
    }
   });
  },
  workerChangeCardReq() {
   workerChangeCard(this.addform)
    .then((res) => {
     console.log(res);
     this.loading = false;
     if (res.data.code == "000000") {
      this.$message.success(res.data.message);
      this.$emit("getList");
     } else {
      this.$message.error(res.data.message);
     }
    })
    .catch((err) => {
     console.log(err);
     this.loading = false;
    });
  },
 },
};
</script>
<style lang="scss" scoped>
</style>


