<template>
 <div>
  <el-dialog title="上传信息" :visible.sync="errorVisible" class="astrict">
   <div style="position: relative;">
    <div class="flex-btn-right" style="position: absolute;top: 3px;right: 0; z-index:1;">
     <span style="margin: 6px;">成功 {{ successData.length }} 条，失败 {{ errorData.length }} 条，合计 {{ successData.length + errorData.length }} 条</span>
    </div>
    <el-tabs v-model="activeName">
     <el-tab-pane label="成功记录" name="1">
      <el-table :data="successData" style="width: 100%">
       <el-table-column label="行" prop="row" width="80"></el-table-column>
       <el-table-column label="员工姓名" prop="employeeName"></el-table-column>
       <el-table-column label="品牌" prop="brandName" ></el-table-column>
       <el-table-column label="门店" prop="hotelName" width="280" ></el-table-column>
       <el-table-column label="职位" prop="position">
         <template slot-scope="scope">
           <div>
             {{showLabelText(scope.row.position,typeLists['cardTypes'])}}
           </div>
         </template>
       </el-table-column>
       <el-table-column label="卡类型" prop="cardType" >
          <template slot-scope="scope">
           <div>
             {{showLabelText(scope.row.cardType,typeLists['cardTypes'])}}
           </div>
         </template>
       </el-table-column>
      </el-table>
     </el-tab-pane>
     <el-tab-pane label="失败记录" name="2">
      <template>
       <el-table :data="errorData" style="width: 100%" id="out-table">
        <el-table-column prop="row" label="行" width="80"></el-table-column>
        <el-table-column prop="name" label="员工姓名" >
        </el-table-column>
        <el-table-column prop="column" label="字段" ></el-table-column>
        <el-table-column prop="errorMessage" label="错误信息"></el-table-column>
       </el-table>
      </template>
     </el-tab-pane>
    </el-tabs>
   </div>


  </el-dialog>
 </div>
</template>

<script>
import { getLongDateTime, secondGetLongTime, showLabel } from "@/api/common.js";

export default {
  props:['typeLists'],
 data() {
  return {
   errorVisible: false, //提示信息弹窗
   activeName: "2",
   successData: [],
   errorData: [],
  };
 },
 methods: {
    showLabelText(val, list) {
   return showLabel(val, list);
  },
  open(res) {
    console.log(res);
   this.errorVisible = true;
   this.successData = res.data;
   if(res.error.length){
     this.activeName='2'
   }else{
     this.activeName='1'
   }
   this.errorData = this.initError(res.error);
  },
  initError(res) {
   let result = [];
   if (res.length) {
    res.forEach((item) => {
     item.errorFields.forEach((item1) => {
      result.push(Object.assign(item1, { row: item.row }));
     });
    });
   }
   return result
  },
 },
};
</script>


<style scoped>
</style>
