<template>
 <div>
  <el-dialog :title="name" top="5%" :visible.sync="showDialog" :close-on-click-modal="false" width="40%">
   <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="修改员工信息" name="edit">
     <changeCard ref="edit" @getList="getList" type="edit" />
    </el-tab-pane>
    <el-tab-pane label="更换卡片" name="changeCard">
     <changeCard ref="changeCard" @getList="getList" type="changeCard" />
    </el-tab-pane>
    <el-tab-pane label="消息推送微信授权二维码" name="opsQR" v-if="showScanQr&&!row.openId">
     <opsQr ref="opsQR" @setUrl="setUrl" />
    </el-tab-pane>
    <!-- <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane> -->
   </el-tabs>

  </el-dialog>
 </div>
</template>
<script>
import changeCard from "./changeCard";
import opsQr from "./opsQr";
export default {
 components: {
  changeCard,
  opsQr,
 },
 data() {
  return {
   showDialog: false,
   tabs: {
    edit: "修改员工信息",
    changeCard: "更换卡片",
    opsQR: "消息推送微信授权二维码",
   },
   activeName: "edit",
   name: "edit",
   row: {},
   url: undefined,
  };
 },
 computed: {
  showScanQr: function () {
   return this.$store.state.roleMenu.includes("staffManage:scanQr");
  },
 },
 methods: {
   close(){
     this.showDialog=false
   },
  handleClick() {
   this.name = this.tabs[this.activeName];
   setTimeout(() => {
     if (!(this.activeName == "opsQR" && this.url)) {
     this.$refs[this.activeName].openSet(this.row);
    }
   }, 10);
  },
  openSet(type, row) {
   this.row = row;
   this.showDialog = true;
   this.activeName = type;
   this.url = undefined;
   this.name = this.tabs[type];
   setTimeout(() => {
    this.$refs[type].openSet(row);
   }, 500);
  },
  getList() {
   this.showDialog = false;
   this.$parent.getList();
  },
  setUrl() {
   this.url = "url";
  },
 },
};
</script>
<style lang="scss" scoped>
</style>