var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.name,
            top: "5%",
            visible: _vm.showDialog,
            "close-on-click-modal": false,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "修改员工信息", name: "edit" } },
                [
                  _c("changeCard", {
                    ref: "edit",
                    attrs: { type: "edit" },
                    on: { getList: _vm.getList },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "更换卡片", name: "changeCard" } },
                [
                  _c("changeCard", {
                    ref: "changeCard",
                    attrs: { type: "changeCard" },
                    on: { getList: _vm.getList },
                  }),
                ],
                1
              ),
              _vm.showScanQr && !_vm.row.openId
                ? _c(
                    "el-tab-pane",
                    {
                      attrs: { label: "消息推送微信授权二维码", name: "opsQR" },
                    },
                    [_c("opsQr", { ref: "opsQR", on: { setUrl: _vm.setUrl } })],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }