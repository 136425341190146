var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加员工",
            top: "5%",
            visible: _vm.showDialog,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addform",
              attrs: {
                model: _vm.addform,
                rules: _vm.myrules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "品牌", prop: "brand" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: "请选择",
                      },
                      on: { change: _vm.selectOne },
                      model: {
                        value: _vm.addform.brand,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "brand", $$v)
                        },
                        expression: "addform.brand",
                      },
                    },
                    _vm._l(_vm.brandSelectData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: item.brandName,
                          value: item.id + _vm.and + item.brandName,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "门店", prop: "hotel" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.addform.hotel,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "hotel", $$v)
                        },
                        expression: "addform.hotel",
                      },
                    },
                    _vm._l(_vm.hotelSelectData, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: item.name,
                          value: item.id + _vm.and + item.name,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "职位", prop: "position" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.addform.position,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "position", $$v)
                        },
                        expression: "addform.position",
                      },
                    },
                    _vm._l(_vm.cardTypes, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "卡类型", prop: "cardType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.addform.cardType,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "cardType", $$v)
                        },
                        expression: "addform.cardType",
                      },
                    },
                    _vm._l(_vm.cardTypes, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "卡号", prop: "cardSn" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容", maxlength: "15" },
                    model: {
                      value: _vm.addform.cardSn,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addform,
                          "cardSn",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addform.cardSn",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "employeeName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容", maxlength: "15" },
                    model: {
                      value: _vm.addform.employeeName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addform,
                          "employeeName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addform.employeeName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号码", prop: "phoneNumber" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入内容", maxlength: "15" },
                    model: {
                      value: _vm.addform.phoneNumber,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addform,
                          "phoneNumber",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addform.phoneNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.confirmAdd("addform")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }