<template>
 <div class="my-box">
  <!-- <el-dialog title="消息推送微信授权二维码" top="5%" :visible.sync="showDialog" width="40%"> -->
  <div class="code" v-loading="loading">
   <!-- <img :src="imgUrl" alt="" class="wx_code"> -->
   <div class="wx_code_bar">
    <el-image lazy class="wx_code" v-if="imgUrl" :src="imgUrl">
    </el-image>
   </div>
   <div class="code_text">
    <div>请用微信扫描二维码</div>
    <div>授权推送相关异常信息</div>
   </div>
  </div>
  <!-- </el-dialog> -->
 </div>
</template>
<script>
import { getOpsBindQr } from "@/api";

export default {
 data() {
  return {
   imgUrl: null,
   loading: false,
   showDialog: false,
  };
 },
 methods: {
  openSet(row) {
   this.showDialog = true;
   this.imgUrl = null;
   this.getData(row.employeeId);
  },
  
  getData(id) {
   this.loading = true;
   getOpsBindQr(id)
    .then((res) => {
     console.log(res);
     this.loading = false;
     if (res.data.code == "000000") {
      this.imgUrl = res.data.data;
       this.$emit('setUrl')
     }
    })
    .catch((err) => {
     this.loading = false;
    });
  },
 },
};
</script>
<style lang="scss" scoped>
.code {
 text-align: center;
}
.wx_code_bar {
 width: 100%;
 height: 250px;
}
.wx_code {
 width: 250px;
 height: 250px;
}
.code_text {
 padding: 0 20px;
 line-height: 22px;
 text-align: center;
}
</style>


