var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-box" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "code",
      },
      [
        _c(
          "div",
          { staticClass: "wx_code_bar" },
          [
            _vm.imgUrl
              ? _c("el-image", {
                  staticClass: "wx_code",
                  attrs: { lazy: "", src: _vm.imgUrl },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._m(0),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "code_text" }, [
      _c("div", [_vm._v("请用微信扫描二维码")]),
      _c("div", [_vm._v("授权推送相关异常信息")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }