var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-box" },
    [
      _c(
        "el-form",
        {
          ref: "addform",
          attrs: {
            model: _vm.addform,
            rules: _vm.myrules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "姓名", prop: "employeeName" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: !(_vm.type == "edit"),
                  placeholder: "请输入内容",
                  maxlength: "15",
                },
                model: {
                  value: _vm.addform.employeeName,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.addform,
                      "employeeName",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addform.employeeName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "手机号", prop: "phoneNumber" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: !(_vm.type == "edit"),
                  placeholder: "请输入手机号",
                  maxlength: "15",
                },
                model: {
                  value: _vm.addform.phoneNumber,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.addform,
                      "phoneNumber",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addform.phoneNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "职位", prop: "position" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: !(_vm.type == "changeCard"),
                    clearable: "",
                    placeholder: "请选择",
                  },
                  model: {
                    value: _vm.addform.position,
                    callback: function ($$v) {
                      _vm.$set(_vm.addform, "position", $$v)
                    },
                    expression: "addform.position",
                  },
                },
                _vm._l(_vm.cardTypes, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "卡类型", prop: "cardType" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: !(_vm.type == "changeCard"),
                    clearable: "",
                    filterable: "",
                    placeholder: "请选择",
                  },
                  model: {
                    value: _vm.addform.cardType,
                    callback: function ($$v) {
                      _vm.$set(_vm.addform, "cardType", $$v)
                    },
                    expression: "addform.cardType",
                  },
                },
                _vm._l(_vm.cardTypes, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "新卡号", prop: "cardSn" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: !(_vm.type == "changeCard"),
                  placeholder: "请输入新卡号",
                  maxlength: "15",
                },
                model: {
                  value: _vm.addform.cardSn,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.addform,
                      "cardSn",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addform.cardSn",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.confirmAdd("addform")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }